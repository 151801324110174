<template>
  <div>
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120" />
      </div>
    </div>
    <div class="m-6">
      <div>
        <h4 class="h4">Are you sure you want to {{ action }} this Site?</h4>
      </div>
      <div class="mt-4">
        <p>This will {{ action }} all managed campaigns on the site.</p>
      </div>
      <!--SAVE AND CANCEL BUTTONS-->
      <div class="flex justify-between items-center mt-16">
        <merge-button-round
          :disabled="isLoading"
          button-type="secondary"
          class="w-30"
          @click="$emit('close')"
        >
          <p>Cancel</p>
        </merge-button-round>
        <merge-button-round
          :disabled="isLoading"
          button-type="primary"
          class="w-34"
          @click="$emit('toggle-site-status')"
        >
          <p>Yes, continue</p>
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  name: 'ToggleSiteStatusModal',
  components: {
    MergeButtonRound,
    BrightbidLoader,
  },
  props: {
    selectedSite: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped></style>
