<template>
  <div class="flex items-center gap-2">
    <div
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
    >
      <span
        v-if="index === 0 && showChevronLeft"
        class="breadcrumb"
      >
        <router-link
          :to="previousParentRoute"
          class="text-bb-text-default"
          @click.native="goToPreviousParent"
        >
          <ic-chevron-left :size="20" />
        </router-link>
      </span>
      <span
        v-if="breadcrumb.to"
        class="breadcrumb"
      >
        <router-link
          :to="breadcrumb.to"
          class="text-bb-text-default"
          >{{ breadcrumb.label }}</router-link
        >
      </span>
      <span
        v-else
        class="breadcrumb-current text-bb-disabled-gray"
        >{{ breadcrumb.label }}</span
      >
      <span
        v-if="index > 0 && index < breadcrumbs.length - 1"
        class="ml-2"
        >/</span
      >
    </div>
  </div>
</template>

<script>
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'

export default {
  name: 'BreadCrumbs',
  components: { IcChevronLeft },
  computed: {
    breadcrumbs() {
      const matchedRoutes = this.$route.matched
      let breadcrumbs = []

      for (let i = 0; i < matchedRoutes.length; i++) {
        const route = matchedRoutes[i]
        const breadcrumbLabel = this.getBreadcrumbLabel(route)
        const breadcrumb = {
          label: breadcrumbLabel,
          to: i < matchedRoutes.length - 1 ? this.getRoutePath(route) : null,
        }

        if (breadcrumbLabel !== false) {
          if (route.meta && route.meta.parent === true && i > 0) {
            // Skip the previous route in the hierarchy when parent: true
            breadcrumbs.pop()
          }

          breadcrumbs.push(breadcrumb)
        }
      }

      return breadcrumbs
    },
    previousParentRoute() {
      // Access the route stack and get the route before the current one
      const routeIndex = this.$router.history.current.matched.length - 2
      if (routeIndex >= 0) {
        return this.getRoutePath(this.$router.history.current.matched[routeIndex])
      }
      // Default to the home route if there's no previous parent
      return '/'
    },
    showChevronLeft() {
      return this.breadcrumbs.length > 2
    },
  },
  methods: {
    getBreadcrumbLabel(route) {
      if (route.meta && route.meta.breadcrumb !== false) {
        return route.meta.breadcrumb ? route.meta.breadcrumb : route.name || ''
      }
      return false
    },
    getRoutePath(route) {
      return route.path
    },
    goToPreviousParent() {
      this.$router.go(-1) // Go back to the previous route
    },
  },
}
</script>

<style scoped></style>
