<template>
  <tbody class="divide-y divide-bb-neutral-50">
    <tr
      v-for="(item, index) in data"
      :key="index"
      class="h-18 text-sm group"
      :class="hoveredRow === index ? 'bg-bb-background-purple-0 cursor-pointer' : ''"
      @mouseenter="hoveredRow = index"
      @mouseleave="hoveredRow = null"
      @click="selectSite(item, organization)"
    >
      <td
        class="px-4 whitespace-no-wrap sticky left-0"
        :class="hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white'"
      >
        {{ item.site }}
      </td>
      <td class="px-4">
        {{ item.google_ads_customer_id }}
      </td>
      <td class="px-4">
        <div class="flex gap-2 items-center">
          <div
            class="h-3 w-3 rounded-full"
            :class="item.isActive ? 'bg-success' : 'bg-bb-error'"
          />
          <p>{{ item.isActive ? 'Active' : 'Inactive' }}</p>
        </div>
      </td>
      <td class="px-4">
        {{ item.url }}
      </td>
      <td class="px-4">
        <div
          v-for="(channel, index) in resolveChannels(item.site_integrations)"
          :key="index"
          class="flex flex-row gap-2 items-center"
        >
          <new-tooltip direction="top">
            <div class="h-6 w-6">
              <google-shopping-tag v-if="channel.label == 'Shopping'" />
              <google-search-tag v-if="channel.label == 'Search'" />
              <ic-amazon-letter-logo v-if="channel.label == 'Amazon'" />
            </div>
            <template #content>{{ channel.label }}</template>
          </new-tooltip>
        </div>
      </td>
      <td class="px-4">
        <p class="break-words">
          {{ truncatedDescription(item) }}
          <a
            class="cursor-pointer text-bb-brand-purple"
            @click.stop="toggleDescription(item.id)"
          >
            {{ shouldTruncate(item) ? (showFullDescription[item.id] ? ' less' : ' more') : '' }}
          </a>
        </p>
      </td>
      <td class="px-4 text-right">
        {{ item.target.map(target => target.toUpperCase()).join(', ') }}
      </td>
      <td class="w-full px-4">
        <div class="flex justify-end">
          <kebab
            :item-data="item"
            :menu="getMenu(item)"
            @select-action="selectAction"
          />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Kebab from '@/components/icon/brightbid/kebab'
import { mapActions } from 'vuex'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import googleShoppingTag from '../../icon/brightbid/google-shopping-tag.vue'
import googleSearchTag from '../../icon/brightbid/google-search-tag.vue'
import icAmazonLetterLogo from '../../icon/ic-amazon-letter-logo.vue'

const TRUNCATE_CHARS = 50

export default {
  name: 'SiteRows',
  components: {
    Kebab,
    NewTooltip,
    googleShoppingTag,
    googleSearchTag,
    icAmazonLetterLogo,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFullDescription: {},
      hoveredRow: null,
    }
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    selectSite(item, organization) {
      const siteData = {
        label: item.site,
        value: item.id,
        site_integration: item.site_integrations,
        site_url: item.url,
        site_organization_id: organization.organizationId,
        site_organization_name: organization.organizationName,
      }
      this.loadSelectedSite(siteData)
      this.$router.push({ name: 'dashboard' })
    },
    toggleDescription(id) {
      this.$set(this.showFullDescription, id, !this.showFullDescription[id])
    },
    truncatedDescription(item) {
      if (this.showFullDescription[item.id]) {
        return item.description
      }

      return this.shouldTruncate(item) ? `${item.description.slice(0, TRUNCATE_CHARS).trim()}...` : item.description
    },
    selectAction(action, item) {
      this.$emit('select-row', { modal: action.value, data: item })
    },
    getMenu(item) {
      return [
        { label: `${item.status === 'enabled' ? 'Pause' : 'Resume'} Site`, value: 'toggle-site-status' },
        { label: 'Edit Site', value: 'edit-site' },
      ]
    },
    shouldTruncate(item) {
      return item.description.length > TRUNCATE_CHARS
    },
    resolveChannels(site_integrations) {
      let channels = []
      const google_ads = site_integrations.includes('google_ads')
      const google_merchant_center = site_integrations.includes('google_merchant_center')
      const amazon = site_integrations.includes('amazon')

      const channelComponents = {
        Shopping: { component: 'googleShoppingTag', label: 'Shopping' },
        Search: { component: 'googleSearchTag', label: 'Search' },
        Amazon: { component: 'img', src: '/content/images/amazon-icon.svg', label: 'Amazon' },
      }

      if (google_ads) {
        if (google_merchant_center) {
          channels.push(channelComponents['Shopping'])
        } else {
          channels.push(channelComponents['Search'])
        }
      }
      if (google_ads && amazon) {
        channels.push(channelComponents['Amazon'])
      }

      return channels.sort((a, b) => a.label.localeCompare(b.label))
    },
  },
}
</script>

<style scoped></style>
