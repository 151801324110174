<template>
  <div>
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white rounded-lg z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120" />
      </div>
    </div>
    <div class="m-6">
      <div>
        <h4 class="h4">Edit Site</h4>
      </div>
      <div class="mt-10 space-y-6">
        <TextInput
          v-model="siteUpdateForm.site"
          placeholder="Site Name"
          label="Name"
          input-name="site-name"
          input-id="site-name"
          :error-message="$v.siteUpdateForm.site.$error ? 'This field is required' : null"
          @focusout="$v.siteUpdateForm.site.$touch"
        />
        <TextArea
          v-model="siteUpdateForm.description"
          label="Description"
          input-name="site-description"
          input-id="site-description"
          placeholder="Business description"
          :max-length="inputLengths.description.max"
          :min-length="inputLengths.description.min"
          :rows="5"
          :error-message="$v.siteUpdateForm.description.$error ? errorMessage : null"
          @focusout="$v.siteUpdateForm.description.$touch"
        />
        <div
          class="flex gap-2 w-fit items-center text-bb-brand-purple cursor-pointer"
          @click="redirectToAllSiteSettings"
        >
          <ic-cog />
          <p class="font-medium">All Site Settings</p>
        </div>
      </div>
      <!--SAVE AND CANCEL BUTTONS-->
      <div class="flex justify-between items-center mt-5">
        <merge-button-round
          :disabled="isLoading"
          button-type="secondary"
          class="w-30"
          @click="$emit('close')"
        >
          <p>Cancel</p>
        </merge-button-round>
        <merge-button-round
          :disabled="isLoading"
          button-type="primary"
          class="w-34"
          @click="updateSite"
        >
          <p>Save</p>
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import TextArea from '@/components/input/brightbid/TextArea.vue'
import IcCog from '@/components/icon/brightbid/ic-cog.vue'
import { mapActions } from 'vuex'

export default {
  name: 'EditSiteModalForm',
  components: {
    MergeButtonRound,
    BrightbidLoader,
    TextInput,
    TextArea,
    IcCog,
  },
  props: {
    selectedSite: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      siteUpdateForm: {
        description: null,
        site: null,
      },
      inputLengths: {
        description: {
          min: 25,
          max: 150,
        },
      },
    }
  },
  computed: {
    errorMessage() {
      if (!this.$v.siteUpdateForm.description.$dirty) return ''
      if (!this.$v.siteUpdateForm.description.maxLength)
        return `Please use at most ${this.inputLengths.description.max} characters.`
      if (!this.$v.siteUpdateForm.description.minLength)
        return `Please use at least ${this.inputLengths.description.min} characters.`
      if (!this.$v.siteUpdateForm.description.required) return 'This field is required'
      return ''
    },
  },
  mounted() {
    this.siteUpdateForm = structuredClone(this.selectedSite)
  },
  validations() {
    return {
      siteUpdateForm: {
        description: {
          required,
          maxLength: maxLength(this.inputLengths.description.max),
          minLength: minLength(this.inputLengths.description.min),
        },
        site: { required },
      },
    }
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    redirectToAllSiteSettings() {
      const selectedSiteData = {
        label: this.selectedSite.site,
        value: this.selectedSite.id,
        url: this.selectedSite.url,
        site_integration: this.selectedSite.site_integrations,
        site_organization_name: this.organization.organizationName,
        site_organization_id: this.organization.organizationId,
      }
      // update the selected site
      this.loadSelectedSite(selectedSiteData)

      this.$router.push({
        name: 'site:settings:site',
      })
    },
    async updateSite() {
      this.$v.siteUpdateForm.$touch()
      if (this.$v.siteUpdateForm.$invalid || !this.siteUpdateForm.target.length || this.isLoading) return

      this.$emit('update-site', this.siteUpdateForm)
    },
  },
}
</script>

<style scoped></style>
