<template>
  <div>
    <div class="h-35 bg-white space-y-2 px-8 py-6">
      <p class="text-sm">
        <breadcrumbs />
      </p>
      <div class="flex flex-row justify-between">
        <h2 class="font-medium">
          {{ isLoading ? '' : organizationName }}
        </h2>
        <MergeButtonRound
          v-if="!standardUser"
          button-type="primary"
          @click="createNewSite(organizationId, organizationName)"
        >
          <template #left-icon>
            <ic-plus
              direction="left"
              class="white"
            />
          </template>
          <p>Add site</p>
        </MergeButtonRound>
      </div>
      <p class="text-sm text-bb-mid-grey">Organization Account</p>
    </div>

    <div class="overflow-y-auto base-scrollbar">
      <div class="mb-20 w-full">
        <bb-table
          class="px-8 py-6"
          :headers="headers"
          :data="organizationSiteRows"
          :is-loading="isLoading"
          :scroll-y="true"
          :sticky-header="true"
          table-height="calc(100vh - 400px)"
        >
          <template #rows="{ tableData }">
            <site-rows
              :data="tableData"
              :organization="{ organizationId, organizationName }"
              @select-row="selectRow"
            />
          </template>
        </bb-table>
      </div>
    </div>

    <!--MODAL COMPONENTS-->
    <bb-base-modal
      v-if="formValues"
      :width="size.width"
      :height="size.height"
      :form-values="formValues"
      :is-modal-loading="isModalLoading"
      @close="closeModal"
    >
      <template #container="{ form, loadingStatus }">
        <edit-site-modal-form
          v-if="form.modal === 'edit-site'"
          :is-loading="loadingStatus"
          :selected-site="form.data"
          :organization="{ organizationId, organizationName }"
          @close="closeModal"
          @update-site="updateSite"
        />
        <toggle-site-status-modal
          v-if="form.modal === 'toggle-site-status'"
          :is-loading="loadingStatus"
          :selected-site="form.data"
          :action="statusAction"
          @close="closeModal"
          @toggle-site-status="toggleSiteStatus"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import SiteRows from '@/components/table/table_rows/SiteRows.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BbTable from '@/components/table/BbTable.vue'
import Toast from '@/components/shared/Toast.vue'
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import EditSiteModalForm from '@/components/modals/forms/EditSiteModalForm.vue'
import ToggleSiteStatusModal from '@/components/modals/forms/ToggleSiteStatusModal.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'

export default {
  name: 'SiteView',
  components: {
    EditSiteModalForm,
    BbBaseModal,
    Breadcrumbs,
    BbTable,
    SiteRows,
    MergeButtonRound,
    ToggleSiteStatusModal,
  },
  data() {
    return {
      headers: [
        {
          value: 'site',
          label: 'Site',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'id',
          label: 'ID',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'isActive',
          label: 'Status',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'url',
          label: 'Url',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'channels',
          label: 'Channels',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'description',
          label: 'Business Description',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'target',
          label: 'B2B/B2C',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'actions',
          label: 'Actions',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      organizationId: null,
      organizationName: null,
      isLoading: false,
      isModalLoading: false,
      formValues: null,
      size: null,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({
      organizationSiteRows: 'site/organizationSiteRows',
    }),
    standardUser() {
      return this.user.role.name === 'standard'
    },
    statusAction() {
      return this.formValues.data.status === 'enabled' ? 'pause' : 'resume'
    },
  },
  async mounted() {
    this.isLoading = true
    this.organizationId = this.$route.params.id
    const { data } = await this.$http.get(`common/organization/${this.organizationId}`)
    this.organizationName = data.name
    await this.getOrganizationSites()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      loadOrganizationSiteList: 'site/loadOrganizationSiteList',
    }),
    async getOrganizationSites() {
      try {
        const { data } = await this.$http.get(`common/organization/${this.organizationId}/sites`)
        await this.loadOrganizationSiteList(data)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch the sites',
            type: 'error',
          },
        })
        return []
      }
    },
    closeModal() {
      this.formValues = null
    },
    selectRow(selectedItem) {
      this.formValues = selectedItem
      switch (this.formValues.modal) {
        case 'edit-site':
          this.size = { width: '450px', height: '500px' }
          break
        case 'toggle-site-status':
          this.size = { width: '560px', height: '220px' }
          break
      }
    },
    createNewSite(organizationId, organizationName) {
      this.$router.push({
        name: 'onboarding-wizard',
        query: {
          desiredState: 'CREATE_SITE_INITIAL',
          organizationId,
          organizationName,
        },
      })
    },
    async toggleSiteStatus() {
      this.isModalLoading = true

      try {
        await this.$http.post(`common/site/${this.formValues.data.id}/${this.statusAction}`, {})
        await this.getOrganizationSites()

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `Site and its campaigns are ${this.statusAction}d`,
            type: 'success',
          },
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isModalLoading = false
        this.closeModal()
      }
    },
    async updateSite(payload) {
      try {
        this.isModalLoading = true

        await this.$http.patch(`common/site/${payload.id}/basic-info`, {
          name: payload.site,
          description: payload.description,
        })

        this.closeModal()

        this.isLoading = true
        await this.getOrganizationSites()
        this.isLoading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.isModalLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.table-container {
  overflow-y: auto;
  height: calc(100vh - 243px);
}
</style>
